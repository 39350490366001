<template>
  <div class="component-root">
    <!-- 附件下载 -->
    <div class="message-header">
      <em class="icon-dot"></em>相关附件下载
    </div>
    <ul class="list lyt-2-columns no-border border-bottom">
      <li
        v-for="(item, index) in message.content"
        :key="index"
      >
        <a :href="item.url" target="_blank">{{item.name}}</a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'BubbleDownload',
  props: {
    message: Object
  }
}
</script>
